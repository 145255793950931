import {validationMixin} from "vuelidate/src";
import { TheMask } from 'vue-the-mask'
const { required, email } =  require('vuelidate/lib/validators');
const axios = require('axios');

export default {
    name: 'contact',
    mixins: [validationMixin],
    components: { TheMask },
    data() {
        return {
            user: {
                name: '',
                email: '',
                phone: '',
                message: '',
                source: process.env.VUE_APP_SOURCE,
                public_key: process.env.VUE_APP_PUBLIC_KEY
            },
            type: null,
            loading: false,
            error: false,
            sent: false,
        }
    },
    validations: {
        user: {
            name: { required },
            email: { required, email },
            phone: { required },
            message: {  }
        }
    },
    methods: {
        submit: async function () {
            this.loading = true;
            let response;
            let url = `${process.env.VUE_APP_API_URL}/api/index/contact`;
            try {
                response = await axios({ headers: {'Accept-Language': 'es_MX'}, method: 'POST', url: url, data: this.user });
                if(response.status === 200) this.sent = true;
            } catch (e) {
                this.error = true;
            } finally {
                this.loading = false;
            }
        }
    }
}
