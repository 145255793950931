import contact from '../components/contact'
let vueScrollTo = require('vue-scrollto');

export default {
    name: 'app',
    components: {contact},
    data() {
        return {
            slide: 1,
            us: 1,
            menu: false
        }
    },
    methods: {
        setSlide: function(slide) {
            if(slide > this.slide) this.slide = slide > 3? 1 : slide;
            else if(slide < this.slide) this.slide = slide < 1? 3 : slide;
        },
        setUs: function() {
            this.us = this.us === 1? 2 : 1;
        },
        scrollTo: function(element) {
            let options = {
                container: 'body',
                easing: 'ease-in',
                lazy: false,
                offset: -50,
                force: true,
                cancelable: true,
                x: false,
                y: true
            };

            vueScrollTo.scrollTo(element, 300, options);
            this.menu = false;
            this.contact = false;
        }
    }
}